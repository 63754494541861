import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import classNames from 'classnames';

const Text = (props) => {
  const [languages, setLanguages] = useState([]);  
  const [texts, setTexts] = useState([]);

  useEffect(() => {
    Axios
    .get('https://us-central1-creation-for-all-f571d.cloudfunctions.net/requests/language')
    .then(res => { setLanguages(res.data) })
    .catch(e => console.log(e));

    Axios
    .get('https://us-central1-creation-for-all-f571d.cloudfunctions.net/requests/content', {params: {page: props.currentPage}})
    .then(res => { setTexts(res.data) })
    .catch(e => console.log(e));
  },[props.currentPage])

  const HandleClick = (id) => {
    props.languageSwitch(id);
    props.currentPage === 1 && props.switchPage(2); 
  }


  return(
    <div className="text__container">
      {languages.map((language, i) => {
        return(
         <div className={(props.currentPage === 1) ? 'text__element text__element--full-page' : (props.currentLanguage === language.language_id) ? 'text__element text__element--selected' : 'text__element text__element--unselected'}>

        
            <div className="text__language" onClick={() => HandleClick(language.language_id)}>
              <span className='text__language__label text__language--rotate regular-text'>{language.name}</span>
            </div>            

            {
            <div className='text__text'>
              <div className="text__text__paragraphe">
        {texts.map((text, i) => {
                return((text.language_id === language.language_id) && <p className="regular-text">{text.text}</p>)
              })}
              </div>

             
            </div> 
            }
          </div>
        )
      })}
    </div>
  )
}

export default Text;