import React from 'react';

import mail from '../../assets/icons/mail.png'
import facebook from '../../assets/icons/facebook.png'
import instagram from '../../assets/icons/instagram.png'

import hkb from '../../assets/icons/hkb.png'; 
import bfh from '../../assets/icons/bfh.png'; 
import kulturesk from '../../assets/icons/kulturesk.png'; 


const Footer = (props) => {

  return(
    <div className="footer">
      <div className="footer__group-item">
        <a href='mailto: ag.kulturesk@gmail.com'><img className="footer__image" src={mail} /></a>
        <a href='https://www.facebook.com/creationforallHKB'><img className="footer__image" src={facebook} /></a>
        <a href='https://www.instagram.com/creation_for_all/'><img className="footer__image" src={instagram} /></a> 
      </div>
      
      <div className="footer__group-item">
        <img className="footer__image" src={hkb} />
        <img className="footer__image" src={kulturesk} />
        <img className="footer__image" src={bfh} />
      </div>
     
    </div>
  )
}

export default Footer;